import { useState, useMemo, useEffect } from "react";
import { useDebounce } from "~/app/shared/utils/hooks";

type usePaginationFooterProps = {
  totalItems: number;
  initialPageSize?: number;
};

export const usePaginationFooter = ({
  totalItems,
  initialPageSize = 5,
}: usePaginationFooterProps) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const pageCount = useMemo(
    () => Math.ceil(totalItems / pageSize),
    [totalItems, pageSize],
  );

  const [newPageNo, setNewPageNo] = useState("");
  const debouncedPageNo = useDebounce(Number(newPageNo), 1000);

  useEffect(() => {
    const checkType = Number.isInteger(debouncedPageNo);
    const checkRange = 0 < debouncedPageNo && debouncedPageNo <= pageCount;

    if (checkType && checkRange) setPageIndex(debouncedPageNo - 1);
    else setNewPageNo("");
  }, [debouncedPageNo, pageCount]);

  useEffect(() => {
    setNewPageNo("");
    setPageIndex(0);
    setPageSize(initialPageSize);
  }, [totalItems, initialPageSize]);

  const getVisibleList = <T>(initialArray: T[]) =>
    initialArray.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);

  return {
    getVisibleList,
    debouncedPageIndex: debouncedPageNo - 1,
    compProps: {
      pageIndex,
      setPageIndex,
      pageSize,
      setPageSize,
      newPageNo,
      setNewPageNo,
      pageCount,
    },
  };
};
